import type { Location } from "@solidjs/router";

export const urls = {
  homepage: () => "/",
  collection: (
    collectionId: string,
    params?: Partial<{
      assetId?: string;
      token?: string;
    }>,
  ) => {
    const query = new URLSearchParams();
    Object.entries(params || {}).forEach(([key, value]) => {
      if (value) {
        query.set(key, value);
      }
    });
    return `/app/collection/${collectionId}${query.toString() ? `?${query.toString()}` : ""}`;
  },
  thread: (threadId: string) => `/app/thread/${threadId}`,
  acceptInvite: (token: string) => `/app/invite/accept?token=${token}`,
  acceptCollectionShareableLink: (token: string) => `/app/invite/collection?token=${token}`,
  logIn: (token?: string, type: "direct" | "link" = "direct") =>
    `/auth/login${token ? `?${type === "direct" ? "invite" : "collectionLink"}=${token}` : ""}`,
  signUp: (token?: string, type: "direct" | "link" = "direct") =>
    `/auth/signup${token ? `?${type === "direct" ? "invite" : "collectionLink"}=${token}` : ""}`,
};

export const isHomePage = (location: Location<unknown>) => location.pathname === urls.homepage();

/**
 * Rebuilds the full path from a location object.
 * @param location - The location object returned from `useLocation()`
 * @returns {string}
 * @example
 * ```ts
 * const location = useLocation();
 * const fullPath = rebuildHref(location);
 * ```
 */
export const rebuildFullPath = (location: Location): string => {
  const search = location.search && location.search !== "?" ? location.search : "";
  const hash = location.hash && location.hash !== "#" ? location.hash : "";
  return location.pathname + search + hash;
};
